<template>
  <div>
    <div class="holder">
      <div class="dashboard-holder">
        <SmallNav />
        <div class="container">
          <div>Hello</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SmallNav from "./SmallNav";
import { Service } from "../store/service";
const Api = new Service();
export default {
  name: "PropertyManagementAgreement",
  // props: {
  //   properties
  // },

  components: {
    SmallNav
  },
  data() {
    return {};
  },
  //   created() {
  //     this.$root.$refs.PropertyManagementAgreement = this === "Property Management Agreement";
  //   },
  mounted() {},
  methods: {}
};
</script>
